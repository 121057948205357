import fileDownload from 'js-file-download';
import {
  map, Observable, of, from,
} from 'rxjs';
import { EditSectionTypes } from '@/lib/ui/panels/CollectionGroup/CollectionGroupEditSections';
import { RightPanels } from '@/lib/ui/panels/RightPanelFactory';
import { DiscoveryDialogTypes } from '@/lib/ui/dialogs/DiscoveryDialogsFactory';
import EditableAsset from '@/lib/ui/dialogs/assets/EditableAsset';
import { Content, InsightContent } from '@/lib/search/discovery';
import DiscoveryNotification from '@/lib/ui/notifier/DiscoveryNotification';
import { EditableTypes } from '@/lib/business/models/editable/editableType';
import SearchParameters from '@/lib/search/discovery/classes/SearchParameters';
import EditPreviewInput from '@/lib/business/models/editable/EditPreviewInput';
import Asset from '@/lib/business/models/Asset';
import assetDownloader from '@/lib/assets/AssetDownloader';
import fileTypesUtil from '@/lib/util/fileTypesUtil';
import DiscoveryDialogOptions from '@/components/ui/dialogs/DiscoveryDialogOptions';
import { CustomizationPanelTypes } from '@/lib/ui/panels/CustomizationPanelManager';
import httpClient from '@/store/uploader/services';
import AssetDocumentForPreview from '@/lib/business/models/AssetDocumentForPreview';
import { ApiFactory } from '../../api/api-factory';

const discoveryApi = ApiFactory.getDiscoveryClient();
const searchIndexApi = ApiFactory.getSearchIndexClient();
const groupsApi = ApiFactory.getGroupsApi();
const dashboardClient = ApiFactory.getDashboardClient();
const businessApi = ApiFactory.getBusinessApi();
const documentInsightApi = ApiFactory.getDocumentInightsClient();
const pageActionsService = ApiFactory.getPageActionsService();
const assetAclService = ApiFactory.getAssetAclService();
const videoIndexerService = ApiFactory.getVideoInsightsService();
const customizationsApi = ApiFactory.getCustomizationsClient();
const reportsApi = ApiFactory.getReportsClient();
const appObjectInsightsApi = ApiFactory.getAppObjectInsightsClient();

const getAsset = (document) => {
  if (document instanceof Asset) {
    return document;
  }

  return document.asset;
};

const docIdExtractor = (document) => {
  if (document instanceof Asset) {
    return document.docId;
  }

  return document.id;
};

export default {
  search({
    commit,
    dispatch,
    state,
    getters,
    rootState,
  }, {
    searchString,
    facetList,
    currentPage,
    rowsPerPage,
    orderBy,
  }) {
    commit('setSearchError', null);
    commit('setSearchString', searchString);

    const doSearch = (index) => {
      const indexId = index.deploymentIndexId;
      dispatch(
        'common/showBusyCursor',
        (getters.formattedSearchString === '' ? 'Loading content...' : `Searching for ${getters.formattedSearchString} ...`),
        { root: true },
      );

      const { searchParams } = state;
      searchParams.filters = facetList?.selectedFacets;
      searchParams.orderBy = orderBy;
      searchParams.page = currentPage;
      searchParams.documentsPerPage = rowsPerPage || state.rowsPerPage;
      searchParams.indexId = indexId;

      const { searchLog } = rootState.common;
      searchParams.source = searchLog.source;
      searchParams.pageName = searchLog.page;

      discoveryApi.search(searchParams)
        .then(
          async (searchResult) => {
            await commit('setSearchResult', searchResult);
            commit('updateFacetListSort');
            commit('setFacetChipsForSemanticSearch', getters.isSemanticSearch);
          }, (error) => {
            commit('setSearchError', error);
          },
        )
        .finally(() => {
          dispatch('common/resetSearchLog', null, { root: true });
          dispatch('common/hideBusyCursor', null, { root: true });
        });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doSearch, { root: true });
  },

  initiateSearch({ dispatch, state, getters }, query) {
    dispatch('search', {
      searchString: query || state.searchString,
      facetList: getters.facetList,
      currentPage: state.currentPage,
      orderBy: state.orderBy,
      documentsPerPage: state.rowsPerPage,
    });
  },

  /**
   * Pagination. Set the current page.
   * @param commit
   * @param pageNum
   */
  setPage({ commit, dispatch }, pageNum) {
    commit('setPage', pageNum);
    dispatch('initiateSearch');
  },

  setPageWithoutInitiatingSearch({ commit }, pageNum) {
    commit('setPage', pageNum);
  },

  setOrderBy({ commit, dispatch }, orderBy) {
    commit('setOrderBy', orderBy);
    dispatch('initiateSearch');
  },

  /**
   * Suggester for search box
   * @param commit
   * @param query
   * @returns {Promise<*>}
   */
  async discoverySuggestions({ commit }, query) {
    const suggestions = await discoveryApi.suggestions(query);
    commit('setSuggestions', suggestions);
    return suggestions;
  },

  async searchIndexSuggestions({ commit }, query) {
    const suggestions = await searchIndexApi.suggestions(query);
    commit('setSuggestions', suggestions);

    return suggestions;
  },
  /**
   * This is a place holder for now. What we want is an action that gets data for the home page
   * @param commit
   * @param query
   */
  ciSearch({ commit, dispatch }) {
    const doSearch = (deploymentIndex) => {
      const indexId = deploymentIndex.deploymentIndexId;
      const params = new SearchParameters('', false);
      params.orderBy = 'metadata_storage_last_modified desc';
      params.indexId = indexId;
      discoveryApi.search(params)
        .then((searchResult) => {
          commit('setCiSearchResults', searchResult);
        });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doSearch, { root: true });
  },

  /**
   * Fetch insights for the collection id and set it as current collection.
   * @param commit
   * @param id
   */
  async getDocument({
    commit,
    dispatch,
    state,
  }, id) {
    commit('setLoading', true);

    const getDocumentAction = async (deploymentIndex) => {
      try {
        const document = await discoveryApi.getDocument(
          id, deploymentIndex?.deploymentIndexId,
        );

        document.currentHighlightPhrase = state.searchString;
        commit('setCurrentDocument', document);

        dispatch('bindAwardsData');
        dispatch('getInsight', document);

        return document;
      } finally {
        commit('setLoading', false);
      }
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', getDocumentAction, { root: true });
  },

  /**
   *
   * @param commit
   * @param document {InsightContent}
   */
  async getInsight({ commit, state }, document) {
    commit('setFilePreviewLoading', true);
    const insight = await discoveryApi.insights({
      id: document.id,
      insightKeys: ['keyphrases'],
      celebrities: document.allCelebrities,
    });
    await customizationsApi.getCustomizations(insight?.asset);
    insight.currentHighlightPhrase = state.searchString;
    document.setAssetDetails(insight?.asset);
    commit('setDocumentInsight', insight);

    const filePreview = insight?.asset?.fullSizePreviewUrl;
    commit('setFilePreview', filePreview);
    commit('setFilePreviewLoading', false);
  },

  bindAwardsData({ commit, state }) {
    const metadataFields = [
      'award_names',
      'award_results',
      'award_tags',
      'award_show_year',
      'cities',
      'campaigns',
      'published_date',
      'clients',
      'brands',
      'agencies',
      'agency_groups',
      'cities',
      'people_credits',
    ];
    const { currentDocument } = state;
    const awardsData = currentDocument?.getMetadataByFields(metadataFields);
    commit('setAwardsData', awardsData);
  },

  async getQuestions({ commit }) {
    commit('setQuestions', []);
    const result = await discoveryApi.questions();
    commit('setQuestions', result);
  },

  /**
   *
   * @param commit
   * @param dispatch
   * @param initialize {boolean}
   * @param searchParams {SearchParameters}
   * @returns {Promise<void>}
   */
  async getMetrics({ commit, dispatch, state }, { initialize, searchParams }) {
    const doGetMetrics = async (deploymentIndex) => {
      dispatch(
        'common/showBusyCursor',
        'Loading content ...',
        { root: true },
      );
      const indexId = deploymentIndex.deploymentIndexId;
      searchParams.indexId = indexId;
      const insightParams = {
        insightType: state.dashboardInsightType,
        top: 10,
        searchParameters: searchParams,
      };

      let metrics = null;
      if (state.dashboardInsightType === 'searched') {
        metrics = await discoveryApi.searchMetrics(insightParams);
      } else {
        metrics = await discoveryApi.metrics(insightParams);
      }

      await commit('setDashboardMetrics', metrics);
      if (initialize) {
        await commit('setInitialMetrics', metrics);
        await commit('bindDefaultFiltersToCurrentFilters');
        await commit('setApplyPublishDateFilter', true);
      }
      dispatch('common/hideBusyCursor', null, { root: true });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doGetMetrics, { root: true });
  },

  async getGroups({ commit, dispatch, state }, { type, page, itemsPerPage }) {
    const doGetGroups = async (deploymentIndex) => {
      const indexId = deploymentIndex.deploymentIndexId;
      const rowsPerPage = itemsPerPage || state.rowsPerPage;
      const currentPage = page - 1;
      dispatch(
        'common/showBusyCursor',
        'Loading groups ...',
        { root: true },
      );
      const groups = await groupsApi.groups(type, indexId, rowsPerPage, currentPage);
      commit('setGroups', groups.rows);
      commit('setTotalPages', groups.count);
      dispatch('setFeaturedGroupsProperty');
      dispatch('common/hideBusyCursor', null, { root: true });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doGetGroups, { root: true });
  },

  async getGroup({ commit, rootState }, groupId) {
    const group = await groupsApi.get(groupId);
    const observer = (user) => {
      console.log('Setting user to Group', user);
      group.updateIsMember(user.email);
    };

    rootState.common.activeUserNotifier.execute(observer);
    commit('setGroup', group);
  },

  async getCollections({ commit, dispatch, state }, { type, page, itemsPerPage }) {
    const doGetCollections = async (deploymentIndex) => {
      const indexId = deploymentIndex.deploymentIndexId;
      const rowsPerPage = itemsPerPage || state.rowsPerPage;
      const currentPage = page - 1;
      state.loadingCollections = true;
      dispatch(
        'common/showBusyCursor',
        'Loading collections ...',
        { root: true },
      );
      const collectionsResult = await businessApi.myCollections(type, indexId, rowsPerPage, currentPage);
      console.group('get collections');
      commit('setCollections', collectionsResult.rows);
      commit('setTotalPages', collectionsResult.count);
      dispatch('setFeaturedCollectionsProperty');
      state.loadingCollections = false;
      console.groupEnd();
      dispatch('common/hideBusyCursor', null, { root: true });
      return collectionsResult.rows;
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doGetCollections, { root: true });
  },

  async getCollection({ commit, rootState }, collectionId) {
    const collection = await businessApi.getCollection(collectionId);

    const observer = (user) => collection.updateIsMember(user.email);
    rootState.common.activeUserNotifier.execute(observer);
    commit('setCollection', collection);
    return collection;
  },

  async getCollectionAssets({ commit, dispatch, state }, collectionId) {
    // try {
    //   dispatch('common/showBusyCursor', 'Fetching Assets', { root: true });
    //   await dispatch('resetCollectionAssets');
    //   const collectionAssets = await businessApi.getCollectionAssets(collectionId, itemsPerPage, pageNum);
    //   await dispatch('fetchInteractionInsightForCollectionAssets', collectionAssets);
    //   collectionAssets.itemsLoaded = true;
    //   commit('setSelectedCollectionAssets', collectionAssets);
    //
    //   return collectionAssets;
    // } catch (e) {
    //   console.error('error while trying to fetch collection assets', e);
    // } finally {
    //   dispatch('common/hideBusyCursor', null, { root: true });
    // }
    let result = null;
    const doSearch = async (index) => {
      dispatch('common/showBusyCursor', 'Loading assets...', { root: true });

      const { searchQuery } = state.collectionAssetSearchInput;
      state.collectionAssetSearchInput.collectionId = collectionId;
      const filters = (state.collectionAssetSearchResult?.facetList?.selectedFacets || [])
        .filter((filter) => !['collections', 'doc_id'].includes(filter.key));

      /* #3804 after navigating to collection details page directly or refreshing the collection
      details page, search input clears on submit. the above operation has something to do with it.
      that's why we're saving the search query a couple of statements before that. and then setting
      it back again.
       */
      state.collectionAssetSearchInput.searchQuery = searchQuery;

      const searchParams = state.collectionAssetSearchInput
        .setFilters(filters)
        .searchParameters;

      searchParams.indexId = index.deploymentIndexId;
      searchParams.orderBy = searchParams.orderBy == null ? 'published_date desc' : searchParams.orderBy;

      result = await businessApi.searchCollectionAssets(collectionId, searchParams);
      await commit('setCollectionSearchResult', result);
      dispatch('common/hideBusyCursor', null, { root: true });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doSearch, { root: true });
    return result;
  },

  async getPinnedCollectionAssets({ commit, dispatch },
    {
      collectionId, itemsPerPage, pageNum,
    }) {
    try {
      dispatch('common/showBusyCursor', 'Fetching Pinned Assets', { root: true });
      await dispatch('resetPinnedCollectionAssets');
      const pinnedCollectionAssets = await businessApi.getCollectionAssets(collectionId, itemsPerPage, pageNum, true);
      pinnedCollectionAssets.itemsLoaded = true;
      commit('setSelectedPinnedCollectionAssets', pinnedCollectionAssets);
      dispatch('fetchInteractionInsightForCollectionAssets', pinnedCollectionAssets);
      return pinnedCollectionAssets;
    } catch (e) {
      console.error('error while trying to fetch pinned collection assets', e);
    } finally {
      dispatch('common/hideBusyCursor', null, { root: true });
    }
    return null;
  },

  async resetCollectionAssets({ commit }) {
    commit('resetCollectionAssets');
  },
  async resetPinnedCollectionAssets({ commit }) {
    commit('resetPinnedCollectionAssets');
  },

  /**
   *
   * @param _
   * @param collectionAssetsResult{PaginatedResult}
   * @returns {Promise<void>}
   */
  async fetchInteractionInsightForCollectionAssets(_, collectionAssetsResult) {
    try {
      const assets = collectionAssetsResult.rows?.map((collectionAsset) => collectionAsset.asset);
      const metrics = await discoveryApi.getAssetsInteractionMetrics(assets);
      if (metrics) {
        collectionAssetsResult.rows?.forEach((collectionAsset) => {
          const { assetId } = collectionAsset.asset;

          if (assetId in metrics) {
            collectionAsset.asset.interactionInsight = metrics[assetId];
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  imageDownload(_, { fileUrl, fileName }) {
    assetDownloader.download(fileUrl, fileName);
  },
  /**
   *
   * @param commit
   * @param state
   * @param document {InsightContent|Content}
   * @returns {Promise<void>}
   */
  async download({ dispatch, commit, rootState }, document) {
    // const fullFileUrl = rootState.common.deploymentIndex.fullFileUrl(document.filename);
    const fullFileUrl = await httpClient.getEntities(`getDownloadUrl?docid=${document.docId}`);

    const notification = new DiscoveryNotification(
      `Downloading ${document.title}`,
      'Downloading ...',
      document,
    );

    const messageFactory = (downloadedPercent) => {
      if (downloadedPercent >= 100) {
        return 'Downloaded';
      }

      return `Downloading ... ${downloadedPercent}%`;
    };

    /* this will update the percentage on notification */
    const onProgress = ({ fileSize, loadedBytes }) => {
      const percentage = Math.floor((loadedBytes / fileSize) * 100);
      notification.message = messageFactory(percentage);
      notification.read = false;
    };

    /* send the notification and open the panel */
    dispatch('common/addAppNotification', notification, { root: true });
    dispatch('common/openNotification', null, { root: true });

    const observable = from(assetDownloader.download(fullFileUrl, document.filename, onProgress));
    const indexId = rootState.common.deploymentIndex.deploymentIndexId;

    /* mark the asset as downloaded */
    const subscription = observable.subscribe({
      next: () => { dispatch('markAsDownloaded', { docId: document.docId, indexId }); },
      complete: () => subscription.unsubscribe(),
    });
  },

  async downloadSelected({ commit }, ids) {
    commit('setLoading', true);
    const data = await discoveryApi.downloadSelected(ids);
    // Having trouble here please delete the comment and add lines of code
    fileDownload(data, 'archive.zip');
    commit('setLoading', false);
  },

  async saveEntity({ dispatch }, { entityType, entity }) {
    try {
      dispatch('common/showBusyCursor', `Saving  ... ${entity.name}`, { root: true });

      if (entityType === 'collection') {
        await dispatch('saveCollection', entity);
      } else if (entityType === 'group') {
        await dispatch('saveGroup', entity);
      } else {
        await dispatch('common/saveDeploymentIndex', entity, { root: true });
      }
    } finally {
      dispatch('common/hideBusyCursor', null, { root: true });
    }
    dispatch('common/hideBusyCursor', null, { root: true });
  },

  async saveCollection({ commit, dispatch, rootState }, collection) {
    const { deploymentIndexId } = rootState
      .common.userDeployments.currentDeployment.currentIndex;
    const isNew = !(collection.id && collection.id > 0);

    const savedCollection = await businessApi.saveCollection(collection, deploymentIndexId);
    const notification = new DiscoveryNotification(
      'Collection',
      `You have updated the collection ${collection.name} successfully`,
      collection,
    );
    collection.updateIsMember(rootState.common.activeUser.email);

    dispatch('common/addAppNotification', notification, { root: true });
    dispatch('getCollection', savedCollection.collectionId);
    if (isNew) {
      commit('addToCollections', savedCollection);
    }

    return savedCollection.collectionId;
  },

  async getCollectionFormInput({ commit, rootState }) {
    try {
      const { deploymentIndexId } = rootState.common.deploymentIndex;
      const input = await businessApi.getCollectionFormInputData(deploymentIndexId);
      commit('setCollectionFormInput', input);
    } catch (e) {
      console.log(JSON.stringify(e));
      // console.log(`Error: ${e.message()}`);
    }
  },

  async updatePinForCollection({ dispatch }, collection) {
    try {
      const action = collection.pinned ? 'Pin' : 'Unpin';
      dispatch('common/showBusyCursor', `${action}ning Collection  ... ${collection.name}`, { root: true });
      await businessApi.updatePinForCollection(collection);
      const notification = new DiscoveryNotification(
        `${action} Collection`,
        `You have ${action}ed collection ${collection.name}`,
        collection,
      );
      dispatch('common/addAppNotification', notification, { root: true });
    } catch (e) {
      console.log(e);
      dispatch('common/hideBusyCursor', null, { root: true });
    }
    dispatch('common/hideBusyCursor', null, { root: true });
  },

  async updateStarForCollection({ dispatch }, collection) {
    try {
      const action = collection.starred ? 'Star' : 'UnStar';
      dispatch('common/showBusyCursor', `${action}ring Collection  ... ${collection.name}`, { root: true });
      await businessApi.updateStarForCollection(collection);
      const notification = new DiscoveryNotification(
        `${action} Collection`,
        `You have ${action}ed collection ${collection.name}`,
        collection,
      );
      dispatch('common/addAppNotification', notification, { root: true });
    } catch (e) {
      console.log(e);
      dispatch('common/hideBusyCursor', null, { root: true });
    }
    dispatch('common/hideBusyCursor', null, { root: true });
  },
  setGroupEditorState({ commit }, value) {
    commit('setShowGroupEditor', value);
  },
  /**
   *
   * @param commit
   * @param dispatch
   * @param state
   * @param collection { Collection }
   */
  async openCollectionEditor({ commit, dispatch, state }, collection) {
    if (state.selectedCollection == null || state.selectedCollection.id !== collection.id) {
      commit('setCollection', collection);
    }
    // wait for the collection details from server
    const collectionWithDetails = await businessApi.getCollectionDetailsForEdit(collection);
    dispatch('openCollectionEditorPanel', collectionWithDetails);
  },

  openCollectionEditorPanel({ commit, dispatch }, collection) {
    commit('setCollection', collection);

    commit('setEditableType', 'collection');
    dispatch('getCollectionFormInput');
    dispatch('common/openPanel', RightPanels.EDIT_COLLECTION_DETAILS, { root: true });
  },

  openCollectionCustomizationsPanel({ commit, dispatch }, collection) {
    commit('setCollection', collection);
    dispatch('setCustomizationsPanelSettings', CustomizationPanelTypes.VISUAL_ELEMENTS);
    dispatch('common/openPanel', RightPanels.PAGE_CUSTOMIZATIONS, { root: true });
  },

  openGroupCustomizationsPanel({ commit, dispatch }, group) {
    commit('setGroup', group);
    dispatch('setCustomizationsPanelSettings', CustomizationPanelTypes.VISUAL_ELEMENTS);
    dispatch('common/openPanel', RightPanels.PAGE_CUSTOMIZATIONS, { root: true });
  },

  async openAssetCustomizationsPanel({ dispatch }, asset) {
    dispatch('common/showBusyCursor', 'Opening customizations ...', { root: true });
    if (asset?.customizationSettings == null) {
      await customizationsApi.getCustomizations(asset);
    }
    dispatch('setCustomizationsPanelSettings', CustomizationPanelTypes.VISUAL_ELEMENTS);
    dispatch('common/customize', asset, { root: true });
    dispatch('common/openPanel', RightPanels.PAGE_CUSTOMIZATIONS, { root: true });
    dispatch('common/hideBusyCursor', 'Opening customizations ...', { root: true });
  },

  async openAssetImageryPanel({ dispatch }) {
    // dispatch('common/showBusyCursor', 'Opening asset imagery ...', { root: true });
    dispatch('common/openPanel', RightPanels.ASSET_IMAGERY, { root: true });
    // dispatch('common/hideBusyCursor', null, { root: true });
  },

  setCustomizationsPanelSettings({ commit }, customizationsType) {
    commit('setCustomizationsPanelTitle', customizationsType);
    commit('setCustomizationsPanelType', customizationsType);
  },

  openEditCollectionMembers({ commit, dispatch }, collection) {
    dispatch('openCollectionEditorPanel', collection);
    commit('setCollectionGroupEditSectionPanel', [EditSectionTypes.MEMBERS]);
  },

  createNewCollection({ dispatch }) {
    const collection = businessApi.newCollection();
    dispatch('openCollectionEditorPanel', collection);
  },

  /**
   *
   * @param dispatch
   * @param group {ListGroup|Group}
   * @returns {Promise<void>}
   */
  async updatePinForGroup({ dispatch }, group) {
    try {
      const action = group.pinned ? 'Pin' : 'Unpin';
      dispatch('common/showBusyCursor', `${action}ning Group  ... ${group.name}`, { root: true });
      await groupsApi.updatePin(group);
      const notification = new DiscoveryNotification(
        `${action} Group`,
        `You have ${action}ed Group ${group.name}`,
        group,
      );
      dispatch('common/addAppNotification', notification, { root: true });
    } catch (e) {
      console.log(e);
      dispatch('common/hideBusyCursor', null, { root: true });
    }
    dispatch('common/hideBusyCursor', null, { root: true });
  },

  /**
   *
   * @param dispatch
   * @param group {ListGroup|Group}
   * @returns {Promise<void>}
   */

  async updateStarForGroup({ dispatch }, group) {
    try {
      const action = group.starred ? 'Star' : 'UnStar';
      dispatch('common/showBusyCursor', `${action}ring Group  ... ${group.name}`, { root: true });
      await groupsApi.updateStar(group);
      const notification = new DiscoveryNotification(
        `${action} Group`,
        `You have ${action}ed Group ${group.name}`,
        group,
      );
      dispatch('common/addAppNotification', notification, { root: true });
    } catch (e) {
      console.log(e);
      dispatch('common/hideBusyCursor', null, { root: true });
    }
    dispatch('common/hideBusyCursor', null, { root: true });
  },

  createNewGroup({ commit, dispatch, rootState }) {
    dispatch('getCollectionFormInput');
    const newGroup = groupsApi.newGroup();

    commit('setGroup', newGroup);
    commit('setEditableType', 'group');
    dispatch('common/openPanel', RightPanels.EDIT_COLLECTION_DETAILS, { root: true });
  },

  async openGroupEditor({ commit, dispatch, state }, group) {
    if (state.selectedGroup == null || group.id !== state.selectedGroup.id) {
      await dispatch('getGroup', group.id);
    }

    dispatch('getCollectionFormInput');
    commit('setEditableType', 'group');
    dispatch('common/openPanel', RightPanels.EDIT_COLLECTION_DETAILS, { root: true });
  },

  async openEditGroupMembers({ commit, dispatch }, group) {
    await dispatch('openGroupEditor', group);
    commit('setCollectionGroupEditSectionPanel', [EditSectionTypes.MEMBERS]);
  },

  /**
   *
   * @param commit
   * @param group{Group}
   * @returns {Promise<*>}
   */
  async saveGroup({ commit, dispatch, rootState }, group) {
    const isNew = !(group.id && group.id > 0);
    let action = 'updated';
    const { deploymentIndexId } = rootState
      .common.userDeployments.currentDeployment.currentIndex;

    const savedGroup = await groupsApi.save(group, deploymentIndexId);
    savedGroup.updateIsMember(rootState.common.activeUser.email);

    commit('setGroup', savedGroup);
    if (isNew) {
      action = 'created';
      commit('addToGroups', savedGroup);
    }

    const notification = new DiscoveryNotification(
      'Group',
      `You have ${action} the group ${savedGroup.name} successfully`,
      savedGroup,
    );

    dispatch('common/addAppNotification', notification, { root: true });
    return savedGroup;
  },

  async openAddCollectionsToGroupDialog({
    commit,
    state,
    dispatch,
    rootState,
  }, group) {
    if (state.selectedGroup == null || state.selectedGroup.id !== group.id) {
      commit('setGroup', group);
    }

    const { currentDeployment } = rootState.common.userDeployments;
    const indexId = currentDeployment.currentIndex.deploymentIndexId;

    dispatch('common/openDialog', DiscoveryDialogTypes.ADD_COLLECTIONS_TO_GROUP, { root: true });
    const collections = await groupsApi.getAvailableCollections(group.id, indexId);
    commit('setAvailableCollections', collections);
  },

  async addToGroup({ dispatch }, { group, collections }) {
    await groupsApi.addCollectionsToGroup(group, collections);
    dispatch('common/closeDialog', null, { root: true });
  },

  async associateCollection({ dispatch }, { collection, groups }) {
    await groupsApi.associateCollectionToGroup(collection, groups);
    dispatch('common/closeDialog', null, { root: true });
  },

  async openAssociateCollectionsDialog({
    commit,
    state,
    dispatch,
    rootState,
  }, collection) {
    if (state.selectedCollection == null || state.selectedCollection.id !== collection.id) {
      commit('setCollection', collection);
    }

    const { currentDeployment } = rootState.common.userDeployments;
    const indexId = currentDeployment.currentIndex.deploymentIndexId;

    dispatch('common/openDialog', DiscoveryDialogTypes.ASSOCIATE_COLLECTION_TO_GROUPS, {
      root: true,
    });
    const groups = await groupsApi.getAvailableGroups(collection.id, indexId);
    commit('setAvailableGroups', groups);
  },

  /**
   * Open the Preview Modal
   * @param commit
   * @param dispatch
   * @param document
   */
  openPreviewModalWindow({ commit, dispatch }, document) {
    commit('setCurrentDocument', document);
    dispatch('common/openDialog', DiscoveryDialogTypes.PREVIEW, {
      root: true,
    });
  },

  async getDocumentForPreviewWindow({ commit, state }, document) {
    const docId = docIdExtractor(document);

    if (state.currentDocumentForPreview != null && state.currentDocumentForPreview.docId === docId) {
      return;
    }

    await commit('setCurrentDocumentForPreviewWindow', new AssetDocumentForPreview());
    const documentForPreview = await discoveryApi.getDocumentForPreview(docId);
    commit('setCurrentDocumentForPreviewWindow', documentForPreview);
  },

  async openContentInsightModalWindow({ commit, dispatch, state }, { document, insight, phrase }) {
    await dispatch('getDocumentForPreviewWindow', document);
    const { fileType } = document;

    console.log('Checking file ype of the current file ', fileType, document);
    if (fileTypesUtil.isAudio(fileType) || fileTypesUtil.isVideo(fileType)) {
      dispatch('openVideoIndexerInsightsModal', document);
      return;
    }

    dispatch('openDocumentInsightsModal', { document, insight, phrase });
  },

  async openVideoIndexerInsightsModal({ commit, dispatch, state }, document) {
    if (document != null) {
      if (document instanceof Asset) {
        await dispatch('getDocument', document.docId);
      } else {
        await dispatch('getDocument', document.id);
      }

      await commit('setVideoIndexerWidgetSettings', null);
      await commit('setVideoInsightsLoading', true);

      const options = new DiscoveryDialogOptions(
        DiscoveryDialogTypes.VIDEO_INDEXER_INSIGHT,
        document,
        null,
      );
      await dispatch('common/openDialog', options, {
        root: true,
      });

      let settings = null;
      if (document instanceof Asset) {
        settings = await videoIndexerService.getInsightsObservable(document.docId);
      } else if (document instanceof InsightContent || document instanceof Content) {
        settings = await videoIndexerService.getInsightsObservable(document.id);
      }
      // const settings = await videoIndexerService.getInsightsObservable(document.id);
      await commit('setVideoIndexerWidgetSettings', settings);
      await commit('setVideoInsightsLoading', false);
    }
  },

  async openDocumentInsightsModal({ commit, dispatch, state }, { document, insight, phrase }) {
    await commit('setContentInsightModalData', { insight: null, phrase: '' });
    setTimeout(async () => {
      if (document instanceof Asset) {
        await dispatch('getDocument', document.docId);
      } else {
        await dispatch('getDocument', document.id);
      }

      await commit('setContentInsightModalData', { insight, phrase });
      await dispatch('common/openDialog', DiscoveryDialogTypes.INSIGHT, {
        root: true,
      });
    }, 10);
  },
  /**
   *
   * @param commit
   * @param dispatch
   * @param state
   * @param document {InsightContent}
   * @param editableProperty {String}
   */
  openEditDocumentProperties({ commit, dispatch, state }, { document, editableProperty }) {
    commit('setEditableAsset', new EditableAsset(
      document,
      state.documentInsight, editableProperty,
    ));

    dispatch('common/openDialog', DiscoveryDialogTypes.EDIT_DOCUMENT, {
      root: true,
    });
  },

  async saveDocumentAsset({ dispatch }, asset) {
    const result = await discoveryApi.saveAsset(asset);
    const notification = new DiscoveryNotification(
      'Update Asset',
      `You have updated ${asset.fileName} successfully`,
      asset,
    );

    dispatch('common/closeDialog', result, { root: true });
    dispatch('common/addAppNotification', notification, { root: true });
  },
  async getDashboardInsights({ commit, dispatch }, searchParams) {
    const getInsights = (index) => {
      const indexId = index.deploymentIndexId;
      searchParams.indexId = indexId;
      dispatch('common/showBusyCursor', 'Fetching Insights ...', { root: true });
      dashboardClient.getInsight(searchParams).then((insights) => {
        commit('setDashboardInsights', insights);
      })
        .finally(() => {
          dispatch('common/hideBusyCursor', null, { root: true });
        });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', getInsights, { root: true });
  },

  async getFeaturedCollections({ commit, dispatch }, imageType = 'featuredCollectionImage') {
    const getFeaturedCollections = (index) => {
      const indexId = index.deploymentIndexId;

      businessApi.featuredCollections(indexId, imageType).then((collections) => {
        commit('setFeaturedCollections', collections);
        dispatch('setFeaturedCollectionsProperty');
      });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable',
      getFeaturedCollections,
      { root: true });
  },

  /**
   * this is my attempt to make the process asynchronous and non-blocking
   * @param commit
   */
  setFeaturedCollectionsProperty({ commit }) {
    commit('markCollectionsAsFeatured');
  },

  async addToFeaturedCollection({ commit, rootState, dispatch }, collection) {
    const index = rootState.common.userDeployments?.currentDeployment?.currentIndex;

    if (index != null) {
      try {
        const updatedCollection = await businessApi.markAsFeaturedCollection(collection, index.deploymentIndexId);
        collection.isFeaturedDate = updatedCollection.result.isFeaturedDate;
        commit('addToFeaturedCollection', collection);
        const notification = new DiscoveryNotification(
          'Featured Collection',
          `You have added  ${collection.name} to Featured Collection`,
          collection,
        );
        dispatch('common/addAppNotification', notification, { root: true });
      } catch (e) {
        console.log(e);
      }
    }
  },

  async removeFromFeaturedCollection({ commit, rootState, dispatch }, collection) {
    const index = rootState.common.userDeployments?.currentDeployment?.currentIndex;

    if (index != null) {
      try {
        await businessApi.removeFromFeaturedCollection(collection, index.deploymentIndexId);
        commit('removeFromFeaturedCollection', collection);
        const notification = new DiscoveryNotification(
          'Featured Collection',
          `You have removed  ${collection.name} from Featured Collection`,
          collection,
        );
        dispatch('common/addAppNotification', notification, { root: true });
      } catch (e) {
        console.log(e);
      }
    }
  },
  async getFeaturedGroups({ commit, dispatch }, imageType = 'featuredGroupImage') {
    const getFeaturedGroups = (index) => {
      const indexId = index.deploymentIndexId;

      groupsApi.featuredGroups(indexId, imageType).then((groups) => {
        commit('setFeaturedGroups', groups);
        dispatch('setFeaturedGroupsProperty');
      });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable',
      getFeaturedGroups,
      { root: true });
  },

  /**
   * this is my attempt to make the process asynchronous and non-blocking
   * @param commit
   */
  setFeaturedGroupsProperty({ commit }) {
    commit('markGroupsAsFeatured');
  },

  async addToFeaturedGroup({ commit, rootState, dispatch }, group) {
    const index = rootState.common.userDeployments?.currentDeployment?.currentIndex;

    if (index != null) {
      try {
        const updatedGroup = await groupsApi.markAsFeaturedGroup(group, index.deploymentIndexId);
        group.isFeaturedDate = updatedGroup.isFeaturedDate;
        commit('addToFeaturedGroup', group);
        const notification = new DiscoveryNotification(
          'Featured Group',
          `You have added  ${group.name} to Featured Group`,
          group,
        );
        dispatch('common/addAppNotification', notification, { root: true });
      } catch (e) {
        console.log(e);
      }
    }
  },

  async removeFromFeaturedGroup({ commit, rootState, dispatch }, group) {
    const index = rootState.common.userDeployments?.currentDeployment?.currentIndex;

    if (index != null) {
      try {
        await groupsApi.removeFromFeaturedGroup(group, index.deploymentIndexId);
        commit('removeFromFeaturedGroup', group);
        const notification = new DiscoveryNotification(
          'Featured Group',
          `You have removed  ${group.name} from Featured Group`,
          group,
        );
        dispatch('common/addAppNotification', notification, { root: true });
      } catch (e) {
        console.log(e);
      }
    }
  },

  async countGroups({ commit, dispatch }) {
    const doCountGroups = async (index) => {
      const { deploymentIndexId } = index;
      const countListResult = await groupsApi.getCounts(deploymentIndexId);
      commit('setGroupCounts', countListResult);
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doCountGroups, { root: true });
  },

  async countCollections({ commit, dispatch }) {
    const doCountCollections = async (index) => {
      const { deploymentIndexId } = index;
      const countListResult = await businessApi.countCollections(deploymentIndexId);
      commit('setCollectionCounts', countListResult);
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doCountCollections, { root: true });
  },

  /**
   *
   * @param dispatch
   * @param collection{Collection}
   * @param asset{Asset}
   * @returns {Promise<void>}
   */
  async removeAsset({ commit, dispatch }, { collection, asset }) {
    if (collection != null) {
      dispatch('common/showBusyCursor', `Removing asset from  ... ${collection.name}`, { root: true });
      try {
        const updatedCollection = await businessApi.removeAsset(collection, asset.assetId);
        const notification = new DiscoveryNotification(
          'Collection',
          `You have removed ${asset.fileName} from collection ${collection.name}`,
          updatedCollection,
        );
        dispatch('common/addAppNotification', notification, { root: true });
        commit('setAssetRemovedFromColleciton', true);
      } catch (e) {
        console.log(e);
      }
      dispatch('common/hideBusyCursor', null, { root: true });
    }
  },

  async pinAssetToCollection({ commit }, { collection, asset }) {
    const pinAssetRequestResult = await discoveryApi.pinAssetToCollection(collection, asset);

    // need to save the state using commit in later step
    return pinAssetRequestResult;
  },

  async unpinAssetToCollection({ commit }, { collection, asset }) {
    const pinAssetRequestResult = await discoveryApi.unpinAssetToCollection(
      collection, asset,
    );

    // need to save the state using commit in later step
    return pinAssetRequestResult;
  },

  async deleteCollectionAction({ commit, dispatch }, collection) {
    const result = await businessApi.deleteCollection(collection);
    if (result) {
      commit('removeCollection', collection.collectionId);
      const notification = new DiscoveryNotification(
        'Collection',
        `You have deleted ${collection.name}`,
      );
      dispatch('common/addAppNotification', notification, { root: true });
    }
  },

  async deleteGroupAction({ commit, dispatch }, group) {
    const result = await groupsApi.deleteGroup(group);
    if (result) {
      commit('removeGroup', group.groupId);
      const notification = new DiscoveryNotification(
        'Group',
        `You have delete ${group.name}`,
      );
      dispatch('common/addAppNotification', notification, { root: true });
    }
  },

  openDeploymentIndexEditorPanel({ commit, dispatch }, deploymentIndex) {
    commit('setSelectedEditableEntity', deploymentIndex);

    commit('setEditableType', EditableTypes.DEPLOYMENT_INDEX);
    commit('setCollectionGroupEditSectionPanel', [EditSectionTypes.THEME]);
    dispatch('common/openPanel', RightPanels.EDIT_COLLECTION_DETAILS, { root: true });
  },

  async removeCollectionFromGroup({ dispatch }, { group, collection }) {
    await groupsApi.removeCollection(group, collection);

    const notification = new DiscoveryNotification(
      'Group',
      `You have removed ${collection.name} from the Group '${group.name}'`,
      collection,
    );

    dispatch('common/addAppNotification', notification, { root: true });
  },

  async getDocumentPreview({ commit }, { docId, force = false }) {
    commit('setFilePreviewLoading', true);
    const preview = await discoveryApi.filePreview(docId, force);
    commit('setFilePreview', preview);
    commit('setFilePreviewLoading', false);
  },

  async openEditAssetPreview({ dispatch, commit }, { document, previewType }) {
    await dispatch('common/openDialog', DiscoveryDialogTypes.EDIT_ASSET_PREVIEW, {
      root: true,
    });

    commit('setEditPreviewInput', new EditPreviewInput(document, previewType));
  },

  async deleteDocument({ dispatch }, asset) {
    const result = await discoveryApi.deleteDocument(asset.docId);
    const notification = new DiscoveryNotification(
      'Asset',
      `You have deleted document ${asset.filename} (${asset.id}) successfully.`,
      null,
    );

    dispatch('common/addAppNotification', notification, { root: true });

    return result;
  },

  async replacePreviewFile({ dispatch }, { assetId, file, useAsThumbnail }) {
    const result = await discoveryApi.replacePreview(assetId, file, useAsThumbnail);
    const notification = new DiscoveryNotification(
      'Asset',
      'You updated preview file for the asset successfully.',
      null,
    );

    dispatch('common/addAppNotification', notification, { root: true });

    return result;
  },

  async replaceAssetImages({ dispatch }, { assetId, assetImageryList }) {
    const result = await discoveryApi.replaceAssetImagery(assetId, assetImageryList);
    const notification = new DiscoveryNotification(
      'Asset',
      'You updated imagery for the asset successfully.',
      null,
    );
    dispatch('common/addAppNotification', notification, { root: true });
    return result;
  },

  async createAssetVersion(_, { assetId, blobUrl }) {
    await discoveryApi.createAssetVersion(assetId, blobUrl);
  },

  async getRelatedCollections({ commit }, docId, rowsPerPage = 25, currentPage = 0) {
    const result = await discoveryApi.relatedCollections(docId, rowsPerPage, currentPage);
    commit('setRelatedCollections', result);
  },

  getAssetSets({ commit }, {
    docId, rowsPerPage = 25, currentPage = 0,
  }) {
    const subscription = discoveryApi
      .getMetadataInsightsObservable(docId, 'set', rowsPerPage, currentPage).subscribe({
        next: (result) => commit('setAssetSets', result),
        complete: () => subscription.unsubscribe(),
      });
  },

  getAssetCampaigns({ commit }, {
    docId, rowsPerPage = 25, currentPage = 0,
  }) {
    const subscription = discoveryApi
      .getMetadataInsightsObservable(docId, 'campaigns', rowsPerPage, currentPage)
      .subscribe({
        next: (result) => commit('setAssetCampaigns', result),
        complete: () => subscription.unsubscribe(),
      });
  },

  getAssetAwards({ commit }, {
    docId, rowsPerPage = 25, currentPage = 0,
  }) {
    const subscription = discoveryApi
      .getMetadataInsightsObservable(docId, 'award_names', rowsPerPage, currentPage)
      .subscribe({
        next: (result) => commit('setAssetAwards', result),
        complete: () => subscription.unsubscribe(),
      });
  },

  getAssetEmbedOrExternalLink({ commit }, {
    docId, rowsPerPage = 25, currentPage = 0,
  }) {
    const subscription = discoveryApi
      .getMetadataInsightsObservable(docId, 'external_url', rowsPerPage, currentPage)
      .subscribe({
        next: (result) => commit('setAssetExternalUrl', result),
        complete: () => subscription.unsubscribe(),
      });
    const subscription1 = discoveryApi
      .getMetadataInsightsObservable(docId, 'external_url_label', rowsPerPage, currentPage)
      .subscribe({
        next: (result) => commit('setAssetExternalUrlLabel', result),
        complete: () => subscription1.unsubscribe(),
      });
    const subscription2 = discoveryApi
      .getMetadataInsightsObservable(docId, 'external_url_action', rowsPerPage, currentPage)
      .subscribe({
        next: (result) => commit('setAssetExternalUrlAction', result),
        complete: () => subscription2.unsubscribe(),
      });
    const subscription3 = discoveryApi
      .getMetadataInsightsObservable(docId, 'external_url_description', rowsPerPage, currentPage)
      .subscribe({
        next: (result) => commit('setAssetExternalUrlDescription', result),
        complete: () => subscription3.unsubscribe(),
      });
  },

  async markAsViewed({ commit }, docId) {
    const insight = await documentInsightApi.markViewed(docId);
    commit('setDocumentInteraction', insight);
  },

  async likeDocument({ commit }, docId) {
    const insight = await documentInsightApi.like(docId);
    commit('setDocumentInteraction', insight);
  },

  async getInteractionInsight({ commit }, docId) {
    const insight = await documentInsightApi.getInsight(docId);
    commit('setDocumentInteraction', insight);
  },

  markAsDownloaded({ dispatch }, { docId, indexId }) {
    const observable = new Observable(async (observer) => {
      const result = await discoveryApi.markAsDownloaded(docId, indexId);
      observer.next(result);
    }).pipe(map(() => from(dispatch('getInteractionInsight', docId))))
      .subscribe();
  },

  async setFacetSortOrder({ commit }, sortOrder) {
    await commit('setFacetSortOrder', sortOrder);
    commit('updateFacetListSort', sortOrder);
  },

  openPageCustomizationsPanel({ dispatch }, panelType) {
    dispatch('setCustomizationsPanelSettings', panelType);
    dispatch('common/openPanel', RightPanels.PAGE_CUSTOMIZATIONS, { root: true });
  },

  /**
   *
   * @param _
   * @param page
   * @param assetOrDocument
   * @returns {Observable<PageActions>|Observable<null>}
   */
  getPageActions(_, { page, assetOrDocument }) {
    if (!assetOrDocument?.pageActions.needsData()) {
      return of(assetOrDocument.pageActions);
    }

    console.log('Fetching page actions for ', assetOrDocument);
    const { pageActions } = assetOrDocument;
    pageActions.isLoading = true;
    pageActions.clear();

    const observable = pageActionsService.get(page, 'asset', assetOrDocument.assetId);
    return observable
      .pipe(map((actions) => assetOrDocument.pageActions.setActions(actions)));
  },

  async canViewAsset(_, assetId) {
    const canView = await assetAclService.canView(assetId);
    return canView;
  },

  async getAssetAssignments({ commit }, assetId) {
    const assignments = await assetAclService.getAssetAssignments(assetId);
    assignments.entityId = assetId;

    commit('setAssetAssignments', assignments);
  },

  async openAssetAssignmentsPanel({ dispatch, commit }, document) {
    const asset = getAsset(document);
    await dispatch('getAssetAssignments', asset.assetId);
    const users = await assetAclService.availableUsers(asset.assetId);
    commit('setAvailableUsersForAssignment', users);

    dispatch('common/openPanel', RightPanels.ASSIGN_TO_USERS, { root: true });
  },

  /**
   *
   * @param _
   * @param assignments{EntityAssignmentResult}
   * @returns {Promise<void>}
   */
  async assignUsersToAsset({ dispatch, commit }, assignments) {
    try {
      commit('common/setShowBusyButtons', true, { root: true });
      const result = await assetAclService.assign(assignments);

      const notification = new DiscoveryNotification(
        'Asset',
        'User assignments updated successfully',
        null,
      );

      dispatch('common/addAppNotification', notification, { root: true });
      commit('common/setShowBusyButtons', false, { root: true });
    } catch (e) {
      console.error('error while assigning users to asset', e);
      commit('common/setShowBusyButtons', false, { root: true });
    }
    // const findUserName = (userId) => assignments
    //   .users
    //   .find((user) => user.userId === userId)
    //   .fullName;

    // const messages = Object.values(result)
    //   .flatMap(
    //     (list) => list.map((row) => `User ${findUserName(row.userId)}: ${row.statusMessage}`),
    //   );
    // console.log(Object.values(result));
  },

  async getAssetsReport({ rootState }, reportType) {
    const { deploymentIndexId } = rootState
      .common.userDeployments.currentDeployment.currentIndex;

    const reports = await reportsApi.assetsReport(deploymentIndexId, reportType);
    return reports;
  },

  async getSearchReport({ rootState }) {
    const { deploymentIndexId } = rootState
      .common.userDeployments.currentDeployment.currentIndex;

    const reports = await reportsApi.searchReport(deploymentIndexId);
    return reports;
  },

  openPreferencesPanel({ dispatch }) {
    dispatch('common/openPanel', RightPanels.PREFERENCES_PANEL, { root: true });
  },

  async markEntityAsViewed({ commit }, { entityId, appObjectType }) {
    const insights = await appObjectInsightsApi.markAsViewed(entityId, appObjectType);
    commit('setAppObjectInteractionInsights', insights);
  },
  refreshHomePageData({ dispatch }) {
    dispatch('getFeaturedGroups');
    dispatch('getFeaturedCollections');
    dispatch('getCollections', 'my');
    dispatch('getGroups', 'my');
  },
  showChartsPanel({ dispatch }) {
    dispatch('common/openPanel', RightPanels.ASSET_INSIGHT_CHARTS, { root: true });
  },
};
